exports.components = {
  "component---src-pages-6-forlunch-tsx": () => import("./../../../src/pages/6forlunch.tsx" /* webpackChunkName: "component---src-pages-6-forlunch-tsx" */),
  "component---src-pages-apologies-tsx": () => import("./../../../src/pages/apologies.tsx" /* webpackChunkName: "component---src-pages-apologies-tsx" */),
  "component---src-pages-au-cookie-policy-tsx": () => import("./../../../src/pages/au/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-au-cookie-policy-tsx" */),
  "component---src-pages-au-faq-tsx": () => import("./../../../src/pages/au/faq.tsx" /* webpackChunkName: "component---src-pages-au-faq-tsx" */),
  "component---src-pages-au-merchant-agreement-tsx": () => import("./../../../src/pages/au/merchant-agreement.tsx" /* webpackChunkName: "component---src-pages-au-merchant-agreement-tsx" */),
  "component---src-pages-au-privacy-policy-tsx": () => import("./../../../src/pages/au/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-au-privacy-policy-tsx" */),
  "component---src-pages-au-terms-and-conditions-tsx": () => import("./../../../src/pages/au/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-au-terms-and-conditions-tsx" */),
  "component---src-pages-ca-cookie-policy-tsx": () => import("./../../../src/pages/ca/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-ca-cookie-policy-tsx" */),
  "component---src-pages-ca-faq-tsx": () => import("./../../../src/pages/ca/faq.tsx" /* webpackChunkName: "component---src-pages-ca-faq-tsx" */),
  "component---src-pages-ca-merchant-agreement-tsx": () => import("./../../../src/pages/ca/merchant-agreement.tsx" /* webpackChunkName: "component---src-pages-ca-merchant-agreement-tsx" */),
  "component---src-pages-ca-privacy-policy-tsx": () => import("./../../../src/pages/ca/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-ca-privacy-policy-tsx" */),
  "component---src-pages-ca-privacy-tsx": () => import("./../../../src/pages/ca-privacy.tsx" /* webpackChunkName: "component---src-pages-ca-privacy-tsx" */),
  "component---src-pages-ca-terms-and-conditions-tsx": () => import("./../../../src/pages/ca/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-ca-terms-and-conditions-tsx" */),
  "component---src-pages-catering-tsx": () => import("./../../../src/pages/catering.tsx" /* webpackChunkName: "component---src-pages-catering-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-delivery-tsx": () => import("./../../../src/pages/delivery.tsx" /* webpackChunkName: "component---src-pages-delivery-tsx" */),
  "component---src-pages-dinner-tsx": () => import("./../../../src/pages/dinner.tsx" /* webpackChunkName: "component---src-pages-dinner-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-fitness-tsx": () => import("./../../../src/pages/fitness.tsx" /* webpackChunkName: "component---src-pages-fitness-tsx" */),
  "component---src-pages-green-tsx": () => import("./../../../src/pages/green.tsx" /* webpackChunkName: "component---src-pages-green-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-joinnow-tsx": () => import("./../../../src/pages/joinnow.tsx" /* webpackChunkName: "component---src-pages-joinnow-tsx" */),
  "component---src-pages-lunchny-tsx": () => import("./../../../src/pages/lunchny.tsx" /* webpackChunkName: "component---src-pages-lunchny-tsx" */),
  "component---src-pages-luxottica-tsx": () => import("./../../../src/pages/luxottica.tsx" /* webpackChunkName: "component---src-pages-luxottica-tsx" */),
  "component---src-pages-mealfix-tsx": () => import("./../../../src/pages/mealfix.tsx" /* webpackChunkName: "component---src-pages-mealfix-tsx" */),
  "component---src-pages-mealpass-tsx": () => import("./../../../src/pages/mealpass.tsx" /* webpackChunkName: "component---src-pages-mealpass-tsx" */),
  "component---src-pages-merchant-agreement-tsx": () => import("./../../../src/pages/merchant-agreement.tsx" /* webpackChunkName: "component---src-pages-merchant-agreement-tsx" */),
  "component---src-pages-nylunch-tsx": () => import("./../../../src/pages/nylunch.tsx" /* webpackChunkName: "component---src-pages-nylunch-tsx" */),
  "component---src-pages-nz-faq-tsx": () => import("./../../../src/pages/nz/faq.tsx" /* webpackChunkName: "component---src-pages-nz-faq-tsx" */),
  "component---src-pages-nz-merchant-agreement-tsx": () => import("./../../../src/pages/nz/merchant-agreement.tsx" /* webpackChunkName: "component---src-pages-nz-merchant-agreement-tsx" */),
  "component---src-pages-nz-privacy-policy-tsx": () => import("./../../../src/pages/nz/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-nz-privacy-policy-tsx" */),
  "component---src-pages-nz-terms-and-conditions-tsx": () => import("./../../../src/pages/nz/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-nz-terms-and-conditions-tsx" */),
  "component---src-pages-partner-tsx": () => import("./../../../src/pages/partner.tsx" /* webpackChunkName: "component---src-pages-partner-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-reasons-tsx": () => import("./../../../src/pages/reasons.tsx" /* webpackChunkName: "component---src-pages-reasons-tsx" */),
  "component---src-pages-sflunch-tsx": () => import("./../../../src/pages/sflunch.tsx" /* webpackChunkName: "component---src-pages-sflunch-tsx" */),
  "component---src-pages-sg-faq-tsx": () => import("./../../../src/pages/sg/faq.tsx" /* webpackChunkName: "component---src-pages-sg-faq-tsx" */),
  "component---src-pages-sg-privacy-policy-tsx": () => import("./../../../src/pages/sg/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-sg-privacy-policy-tsx" */),
  "component---src-pages-sg-terms-and-conditions-tsx": () => import("./../../../src/pages/sg/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-sg-terms-and-conditions-tsx" */),
  "component---src-pages-suburb-faq-tsx": () => import("./../../../src/pages/suburb-faq.tsx" /* webpackChunkName: "component---src-pages-suburb-faq-tsx" */),
  "component---src-pages-suburb-tsx": () => import("./../../../src/pages/suburb.tsx" /* webpackChunkName: "component---src-pages-suburb-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-textforlunch-tsx": () => import("./../../../src/pages/textforlunch.tsx" /* webpackChunkName: "component---src-pages-textforlunch-tsx" */),
  "component---src-pages-uk-cookie-policy-tsx": () => import("./../../../src/pages/uk/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-uk-cookie-policy-tsx" */),
  "component---src-pages-uk-faq-tsx": () => import("./../../../src/pages/uk/faq.tsx" /* webpackChunkName: "component---src-pages-uk-faq-tsx" */),
  "component---src-pages-uk-merchant-agreement-tsx": () => import("./../../../src/pages/uk/merchant-agreement.tsx" /* webpackChunkName: "component---src-pages-uk-merchant-agreement-tsx" */),
  "component---src-pages-uk-privacy-policy-tsx": () => import("./../../../src/pages/uk/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-uk-privacy-policy-tsx" */),
  "component---src-pages-uk-terms-and-conditions-tsx": () => import("./../../../src/pages/uk/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-uk-terms-and-conditions-tsx" */)
}

